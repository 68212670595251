import React, { useState, useEffect } from "react";
import { Routes, Route, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Navbar, Nav, Container, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify"; // Import ToastContainer and toast
import 'react-toastify/dist/ReactToastify.css'; // Import toast CSS
import ApiService from "./services/ApiService.js";
import "./App.css";
import "bootstrap-icons/font/bootstrap-icons.css"; // Import Bootstrap Icons

// Import all components for routes
import Dashboard from "./components/AdminDashboard.js";
import CustomerManagement from "./components/CustomerManagement.js";
import CustomerDetails from "./components/CustomerDetails.js";
import AdminUserManagement from "./components/AdminUserManagement.js";
import AdminUserDetails from "./components/AdminUserDetails.js";
import ApiUserManagement from "./components/ApiUserManagement.js";
import SettingManagement from "./components/SettingManagement.js";
import IntegrationManagement from "./components/IntegrationManagement.js";
import IntegrationDetails from "./components/IntegrationDetails.js";
import ApiUserDetails from "./components/ApiUserDetails.js";
import AdminLogin from "./components/AdminLogin.js";
import ShipmentManagement from "./components/ShipmentManagement.js";
import ShipmentDetails from "./components/ShipmentDetails.js";
import ShipmentLabelViewer from "./components/ShipmentLabel.js";
import AdminSignup from './components/AdminSignup.js';
import RatesManagement from './components/RatesManagement.js';
import RatesUpload from './components/RatesUpload.js';
import AmazonZoneManagement from './components/AmazonZoneManagement.js';
import RateCardManagement from "./components/RateCardManagement.js";
import RateCardUpload from "./components/RateCardUpload.js";
import RateCardPreview from "./components/RateCardPreview.js";
import RateCardPreviewById from "./components/RateCardPreviewById.js";
import DiscountManagement from "./components/DiscountManagement.js";
import DiscountProfileDetails from "./components/DiscountProfileDetails.js";

const App = () => {
    const [sidebarVisible, setSidebarVisible] = useState(true); // State to toggle sidebar
    const [user, setUser] = useState(null); // State to store user profile info
    const location = useLocation();
    const navigate = useNavigate();

    // Define the routes where the sidebar should be hidden
    const hideSidebarRoutes = ["/admin/login", "/admin/signup"]; // Add /signup route here if you have it

    const isSidebarVisible = !hideSidebarRoutes.includes(location.pathname);

    useEffect(() => {
        // Fetch the user profile only if not on the login page
        if (!hideSidebarRoutes.includes(location.pathname)) {
            const fetchUserProfile = async () => {
                try {
                    const response = await ApiService.get("api/admin/profile");
                    setUser(response); // Assume response contains user data
                } catch (error) {
                    console.error("Failed to fetch user profile", error);
                    toast.error("Failed to fetch user profile."); // Trigger toast error
                    navigate("/admin/login"); // Redirect to login if error occurs
                }
            };
            fetchUserProfile(); // Fetch profile after login
        }
    }, [location.pathname, navigate]);

    // Handle logout functionality
    const handleLogout = async () => {
        try {
            // Call the API to log out
            await ApiService.post("/auth/logout", {});
            navigate("/admin/login"); // Redirect to the login page on successful logout
        } catch (error) {
            console.error("Logout failed", error);
            toast.error("An error occurred while logging out."); // Show error toast on logout failure
        }
    };

    return (
        <div className="app-container d-flex">
            {/* Toast container for global toast notifications */}
            <ToastContainer 
                position="top-right" 
                autoClose={5000} 
                hideProgressBar
                newestOnTop
                closeOnClick
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            
            {/* Conditionally render the Sidebar */}
            {isSidebarVisible && (
                <div className={`sidebar ${sidebarVisible ? "show" : "hide"}`}>
                    <Navbar bg="dark" variant="dark" className="d-flex flex-column p-3 vh-100">
                        <Navbar.Brand href="/">Rocketship Industries</Navbar.Brand>
                        {/* User Profile Section */}
                        {user ? (
                            <div className="text-white my-3 d-flex align-items-center">
                                <i className="bi bi-person-circle me-3" style={{ fontSize: '2rem' }}></i> {/* User icon */}
                                <div>
                                    <h5>{user.name}</h5>
                                    <p className="small">{user.email}</p>
                                </div>
                            </div>
                        ) : null}

                        <Nav className="flex-column">
                            {/* Main Links */}
                            <NavLink to="/admin" className="nav-link" end>
                                <i className="bi bi-speedometer2 me-2"></i> Dashboard
                            </NavLink>

                            {/* Admin Management Group */}
                            <h5 className="text-white mt-4 mb-3">Admin Management</h5>
                            <NavLink to="/admin/customers" className="nav-link">
                                <i className="bi bi-people-fill me-2"></i> Customer
                            </NavLink>
                            <NavLink to="/admin/admin_users" className="nav-link">
                                <i className="bi bi-person-badge-fill me-2"></i> Admin User
                            </NavLink>

                            {/* API Management Group */}
                            <h5 className="text-white mt-4 mb-3">API Management</h5>
                            <NavLink to="/admin/api_users" className="nav-link">
                                <i className="bi bi-person-circle me-2"></i> API Users
                            </NavLink>
                            <NavLink to="/admin/integrations" className="nav-link">
                                <i className="bi bi-plug-fill me-2"></i> Integrations
                            </NavLink>
                            <NavLink to="/admin/shipments" className="nav-link">
                                <i className="bi bi-truck me-2"></i> Shipments
                            </NavLink>
                            <NavLink to="/admin/amazon_zones" className="nav-link">
                                <i className="bi bi-grid-3x3 me-2"></i> Amazon Zones
                            </NavLink>
                            <NavLink to="/admin/rate_cards" className="nav-link">
                                <i className="bi bi-tag me-2"></i> Rate Cards
                            </NavLink>
                            <NavLink to="/admin/discounts" className="nav-link">
                                <i className="bi bi-percent me-2"></i> Surcharge
                            </NavLink>
                            <NavLink to="/admin/settings" className="nav-link">
                                <i className="bi bi-gear-fill me-2"></i> Settings
                            </NavLink>
                        </Nav>

                        {/* Logout Button */}
                        <Button variant="outline-light" className="mt-auto" onClick={handleLogout}>
                            Logout
                        </Button>
                    </Navbar>
                </div>
            )}

            {/* Main Content */}
            <div
                className={`main-content flex-grow-1 d-flex flex-column ${!isSidebarVisible ? "w-100" : ""}`}
                style={{ position: "relative", marginLeft: isSidebarVisible ? "250px" : "0px" }}
            >
                <Container fluid>
                    {/* Route Definitions */}
                    <Routes>
                        <Route path="/admin" element={<Dashboard />} />
                        <Route path="/admin/login" element={<AdminLogin />} />
                        <Route path="/admin/signup" element={<AdminSignup />} />
                        <Route path="/admin/customers" element={<CustomerManagement />} />
                        <Route path="/admin/customers/create" element={<CustomerDetails />} />
                        <Route path="/admin/customers/:id" element={<CustomerDetails />} />
                        <Route path="/admin/admin_users" element={<AdminUserManagement />} />
                        <Route path="/admin/admin_users/:id" element={<AdminUserDetails />} />
                        <Route path="/admin/api_users" element={<ApiUserManagement />} />
                        <Route path="/admin/api_users/:id" element={<ApiUserDetails />} />
                        <Route path="/admin/integrations" element={<IntegrationManagement />} />
                        <Route path="/admin/integrations/:id" element={<IntegrationDetails />} />
                        <Route path="/admin/settings" element={<SettingManagement />} />
                        <Route path="/admin/shipments" element={<ShipmentManagement />} />
                        <Route path="/admin/shipments/:trackingNumber" element={<ShipmentDetails />} />
                        <Route path="/admin/shipments/:trackingNumber/label" element={<ShipmentLabelViewer />} />
                        <Route path="/admin/rates" element={<RatesManagement />} />
                        <Route path="/admin/rates/upload" element={<RatesUpload />} />
                        <Route path="/admin/amazon_zones" element={<AmazonZoneManagement />} />
                        <Route path="/admin/rate_cards" element={<RateCardManagement />} />
                        <Route path="/admin/rate_cards/upload" element={<RateCardUpload />} />
                        <Route path="/admin/rate_cards/preview" element={<RateCardPreview />} />
                        <Route path="/admin/rate_cards/preview/:id" element={<RateCardPreviewById />} />
                        <Route path="/admin/discounts" element={<DiscountManagement />} />
                        <Route path="/admin/discounts/:id" element={<DiscountProfileDetails />} />
                    </Routes>
                </Container>
            </div>
        </div>
    );
};

export default App;