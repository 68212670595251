import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col, Accordion } from "react-bootstrap";
import IntegrationConfiguration from "../utils/IntegrationConfiguration.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CreateIntegration = ({
    mode = "create", // 'create' or 'edit'
    integration,
    onIntegrationChange,
    rateCards,
    markups,
    onSubmit,
}) => {
    const [customIntegrationData, setCustomIntegrationData] = useState({});
    const [fieldErrors, setFieldErrors] = useState({});
    const integrationTypes = IntegrationConfiguration.getAllKeys();

    useEffect(() => {
        if (mode === "edit") {
            const integrationDetail = IntegrationConfiguration.getIntegrationByKey(
                integration.integration_type
            );
            setCustomIntegrationData(integration.integration_details || integrationDetail?.setupFields || {});
        } else {
            if (integration) {
                resetForm();
            }
        }
    }, [mode, integration]);

    const resetForm = () => {
        const integrationDetail = IntegrationConfiguration.getIntegrationByKey(
            integration.integration_type
        );
        setCustomIntegrationData(integrationDetail?.setupFields || {});
        setFieldErrors({});
    };

    const handleIntegrationTypeChange = (e) => {
        const { value } = e.target;
        const integrationDetail = IntegrationConfiguration.getIntegrationByKey(value);
        onIntegrationChange({
            ...integration,
            integration_type: value,
            integration_details: {},
        });
        setCustomIntegrationData(integrationDetail?.setupFields || {});
    };

    const handleCustomFieldChange = (name, value) => {
        const updatedDetails = {
            ...customIntegrationData,
            [name]: value || "",
        };
        setCustomIntegrationData(updatedDetails);
        onIntegrationChange({ ...integration, integration_details: updatedDetails });
        validateField(name, value || "");
    };

    const handleNestedFieldChange = (section, field, value) => {
        const updatedDetails = {
            ...customIntegrationData,
            [section]: {
                ...customIntegrationData[section],
                [field]: value || "",
            },
        };
        setCustomIntegrationData(updatedDetails);
        onIntegrationChange({ ...integration, integration_details: updatedDetails });
        validateField(field, value || "", section);
    };

    const validateField = (name, value, section = null) => {
        const defaultFields =
            IntegrationConfiguration.getIntegrationByKey(integration.integration_type)?.setupFields || {};
        const fieldConfig = section ? defaultFields[section]?.[name] : defaultFields[name];
        const errorKey = section ? `${section}.${name}` : name;

        let errorMessage = "";

        if (fieldConfig?.required && !value) {
            errorMessage = `${name
                .replace(/([A-Z])/g, " $1")
                .replace(/\b\w/g, (char) => char.toUpperCase())} is required`;
        }

        if (errorMessage) {
            setFieldErrors((prev) => ({ ...prev, [errorKey]: errorMessage }));
        } else {
            setFieldErrors((prev) => {
                const updatedErrors = { ...prev };
                delete updatedErrors[errorKey];
                return updatedErrors;
            });
        }
    };

    const renderField = (key, value = "", section = null) => {
        const errorKey = section ? `${section}.${key}` : key;
        const isError = !!fieldErrors[errorKey];

        const fieldValue = typeof value === "string" ? value : "";

        return (
            <Col md={6} key={key}>
                <Form.Group className="mb-2">
                    <Form.Label className="form-label-sm">
                        {key
                            .replace(/([A-Z])/g, " $1")
                            .replace(/\b\w/g, (char) => char.toUpperCase())}
                        {IntegrationConfiguration.getIntegrationByKey(integration.integration_type)
                            ?.setupFields?.[section]?.[key]?.required && (
                            <span className="text-danger"> *</span>
                        )}
                    </Form.Label>
                    <Form.Control
                        size="sm"
                        type="text"
                        name={key}
                        value={fieldValue}
                        onChange={(e) =>
                            section
                                ? handleNestedFieldChange(section, key, e.target.value)
                                : handleCustomFieldChange(key, e.target.value)
                        }
                        isInvalid={isError}
                    />
                    {isError && (
                        <Form.Control.Feedback type="invalid">
                            {fieldErrors[errorKey]}
                        </Form.Control.Feedback>
                    )}
                </Form.Group>
            </Col>
        );
    };

    if (!integration) {
        return <></>;
    }

    return (
        <>
            <ToastContainer />
            <Form onSubmit={onSubmit}>
                <Form.Group className="mb-3">
                    <Form.Label>Integration Type</Form.Label>
                    <Form.Select
                        name="integration_type"
                        value={integration.integration_type || ''}
                        onChange={handleIntegrationTypeChange}
                        required
                        disabled={mode === "edit"} // Disable changing type in edit mode
                    >
                        <option value="" disabled>
                            Select Integration Type
                        </option>
                        {integrationTypes.map((type) => (
                            <option key={type} value={type}>
                                {type}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Rate Card</Form.Label>
                    <Form.Select
                        name="rate_card_id"
                        value={integration.rate_card_id}
                        onChange={(e) =>
                            onIntegrationChange({
                                ...integration,
                                rate_card_id: e.target.value,
                            })
                        }
                    >
                        <option value="">Select Rate Card (Optional)</option>
                        {rateCards.rateCards.map((rateCard) => (
                            <option key={rateCard.id} value={rateCard.id}>
                                {rateCard.name}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Discount Assignment</Form.Label>
                    <Form.Select
                        name="markup_profile_id"
                        value={integration.markup_profile_id}
                        onChange={(e) =>
                            onIntegrationChange({
                                ...integration,
                                markup_profile_id: e.target.value,
                            })
                        }
                    >
                        <option value="">Select Discount (Optional)</option>
                        {markups.profiles.map((markup) => (
                            <option key={markup.id} value={markup.id}>
                                {markup.name}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
                <Accordion>
                    {Object.entries(customIntegrationData).map(([section, fields], idx) => (
                        <Accordion.Item eventKey={idx.toString()} key={section}>
                            <Accordion.Header>
                                {section
                                    .replace(/([A-Z])/g, " $1")
                                    .replace(/\b\w/g, (char) => char.toUpperCase())}
                            </Accordion.Header>
                            <Accordion.Body>
                                <Row>
                                    {typeof fields === "object" && !Array.isArray(fields)
                                        ? Object.entries(fields).map(([key, value]) =>
                                              renderField(key, value, section)
                                          )
                                        : renderField(section, fields)}
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
                <div className="d-flex">
                    <Button variant="primary" type="submit" className="mt-3 ms-auto">
                        {mode === "create" ? "Create Integration" : "Update Integration"}
                    </Button>
                </div>
            </Form>
        </>
    );
};

export default CreateIntegration;