import React, { useEffect, useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ApiService from '../services/ApiService.js';
import { Table, Button, Pagination, Spinner, Form, Row, Col } from 'react-bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';

const ShipmentManagement = () => {
    const [shipments, setShipments] = useState([]);
    const [loading, setLoading] = useState(true);

    // Sorting, pagination, and search
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [sortField, setSortField] = useState('created_at');
    const [sortOrder, setSortOrder] = useState('desc');
    const [search, setSearch] = useState('');
    const pageSize = 10;

    const navigate = useNavigate();

    const fetchShipments = useCallback(async () => {
        setLoading(true);
        try {
            const params = { page: currentPage, pageSize, sortField, sortOrder, search };
            const data = await ApiService.get('api/shipment', params);
            setShipments(data.shipments || []);
            setTotalPages(data.totalPages || 1);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching shipments:', error);
            setLoading(false);
        }
    }, [currentPage, sortField, sortOrder, search]);

    useEffect(() => {
        fetchShipments();
    }, [fetchShipments]);

    const handleSort = (field) => {
        const newSortOrder = (sortField === field && sortOrder === 'asc') ? 'desc' : 'asc';
        setSortField(field);
        setSortOrder(newSortOrder);
    };

    const renderPagination = () => {
        const paginationItems = [];

        // Add "First" page
        if (currentPage > 2) {
            paginationItems.push(
                <Pagination.Item key={1} onClick={() => setCurrentPage(1)}>
                    1
                </Pagination.Item>
            );
            if (currentPage > 3) {
                paginationItems.push(<Pagination.Ellipsis key="start-ellipsis" />);
            }
        }

        // Add "Previous" page with number
        if (currentPage > 1) {
            paginationItems.push(
                <Pagination.Item
                    key="prev"
                    onClick={() => setCurrentPage(currentPage - 1)}
                >
                    {currentPage - 1}
                </Pagination.Item>
            );
        }

        // Add "Current" page
        paginationItems.push(
            <Pagination.Item key={currentPage} active>
                {currentPage}
            </Pagination.Item>
        );

        // Add "Next" page with number
        if (currentPage < totalPages) {
            paginationItems.push(
                <Pagination.Item
                    key="next"
                    onClick={() => setCurrentPage(currentPage + 1)}
                >
                    {currentPage + 1}
                </Pagination.Item>
            );
        }

        // Add "Last" page
        if (currentPage < totalPages - 1) {
            if (currentPage < totalPages - 2) {
                paginationItems.push(<Pagination.Ellipsis key="end-ellipsis" />);
            }
            paginationItems.push(
                <Pagination.Item key={totalPages} onClick={() => setCurrentPage(totalPages)}>
                    {totalPages}
                </Pagination.Item>
            );
        }

        return paginationItems;
    };

    return (
        <div className="container">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h5 className="mb-0" style={{ fontSize: '1rem' }}>Shipment Management</h5>
            </div>

            {/* Search Form */}
            <Form className="mb-2" onSubmit={(e) => { e.preventDefault(); fetchShipments(); }}>
                <Row>
                    <Col>
                        <Form.Control
                            type="text"
                            size="sm"
                            placeholder="Search by tracking number, customer, or integration..."
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </Col>
                </Row>
            </Form>

            {/* Loading Spinner */}
            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '150px' }}>
                    <Spinner animation="border" size="sm" />
                </div>
            ) : (
                <Table striped bordered hover size="sm" responsive>
                    <thead>
                        <tr style={{ fontSize: '0.85rem' }}>
                            <th onClick={() => handleSort('primary_tracking_number')}>
                                Tracking Number {sortField === 'primary_tracking_number' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}<br />
                                Carrier (Integration)
                            </th>
                            <th onClick={() => handleSort('customer_name')}>
                                API User<br />Customer {sortField === 'customer_name' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                            </th>
                            <th onClick={() => handleSort('created_at')}>
                                Created At {sortField === 'created_at' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                            </th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {shipments.length > 0 ? (
                            shipments.map((shipment) => (
                                <tr key={shipment.id} style={{ fontSize: '0.85rem' }}>
                                    <td>
                                        <span className="fs-6">{shipment.primary_tracking_number}</span>{' '}
                                        {shipment.voided_at ? (
                                            <span className="border border-dark px-1 py-1 rounded text-light bg-dark" style={{ fontSize: '0.65rem' }}>
                                                <i className="bi bi-x-circle" title={`Void Date: ${new Date(shipment.voided_at).toLocaleString()}`}></i> Voided
                                            </span>
                                        ) : ''}
                                        <br />
                                        {shipment.carrier}{' '}
                                        (
                                            <Link to={`/admin/integrations/${shipment.integration_id}`}>
                                                {shipment.integration_name}
                                            </Link>
                                        )
                                    </td>
                                    <td>
                                        <Link to={`/admin/api_users/${shipment.api_user_id}`}>
                                            {shipment.api_user_name}
                                        </Link><br />
                                        <Link to={`/admin/customers/${shipment.customer_id}`}>
                                            {shipment.customer_name}
                                        </Link>
                                    </td>
                                    <td>{new Date(shipment.created_at).toLocaleString()}</td>
                                    <td>
                                        <Button
                                            variant="outline-primary"
                                            size="sm"
                                            onClick={() => navigate(`/admin/shipments/${shipment.primary_tracking_number}`)}
                                        >
                                            <i className="bi bi-eye-fill"></i>
                                        </Button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="6" className="text-center">No shipments found</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            )}

            {/* Pagination */}
            <div className="d-flex justify-content-end mt-2">
                <Pagination size="sm">{renderPagination()}</Pagination>
            </div>
        </div>
    );
};

export default ShipmentManagement;