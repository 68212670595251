import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Table, Spinner, Button, Breadcrumb, Badge, Row, Col } from 'react-bootstrap';
import ApiService from '../services/ApiService.js';
import { toast } from 'react-toastify';

const RateCardPreviewById = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [rateCard, setRateCard] = useState(null);

    useEffect(() => {
        const fetchRateCard = async () => {
            try {
                const response = await ApiService.get(`/api/rate-card/preview/${id}`);
                // Ensure the API includes `fuelIncludedOz` and `fuelIncludedLb`
                setRateCard(response);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching rate card:', error);
                toast.error('Failed to fetch rate card.');
                setLoading(false);
            }
        };

        fetchRateCard();
    }, [id]);

    if (loading) {
        return (
            <Container className="text-center">
                <Spinner animation="border" />
            </Container>
        );
    }

    if (!rateCard) {
        return (
            <Container>
                <p>Rate card not found.</p>
                <Button onClick={() => navigate('/admin/rate_cards')}>Back</Button>
            </Container>
        );
    }

    // Destructure properties from the API response
    const { name, spreadsheet, zones, fuelIncludedOz, fuelIncludedLb } = rateCard;

    return (
        <Container>
            <Breadcrumb className="mb-3">
                <Breadcrumb.Item onClick={() => navigate('/admin/rate_cards')}>Rate Cards</Breadcrumb.Item>
                <Breadcrumb.Item active>Rate Card Preview</Breadcrumb.Item>
            </Breadcrumb>
            <Row className="align-items-center mb-3">
                <Col>
                    <p>
                        <strong>Name:</strong> {name}
                    </p>
                    {/* Badges for fuelIncluded */}
                    <div className="mb-3">
                        {fuelIncludedOz ? (
                            <Badge bg="info" text="dark" className="me-2">
                                OZ - Fuel Included
                            </Badge>
                        ) : null}
                        {fuelIncludedLb ? (
                            <Badge bg="info" text="dark">
                                LB - Fuel Included
                            </Badge>
                        ) : null}
                    </div>
                </Col>
            </Row>
            <div style={{ overflowX: 'auto', maxHeight: '80vh', position: 'relative' }}>
                <Table striped bordered hover size="sm" style={{ tableLayout: 'fixed' }}>
                    <thead style={{ position: 'sticky', top: 0, backgroundColor: '#fff', zIndex: 1 }}>
                        <tr>
                            <th>Weight</th>
                            {zones.map((zone, index) => (
                                <th key={index}>{zone}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {spreadsheet.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                <td>{row.Weight}</td>
                                {zones.map((zone, zoneIndex) => (
                                    <td key={zoneIndex}>{row[zone]}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </Container>
    );
};

export default RateCardPreviewById;