import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ApiService from "../services/ApiService.js";
import { Container, Form, Button, Row, Col, Table, Breadcrumb } from "react-bootstrap";

const DiscountProfileDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const isCreateMode = id === "create";
    const [loading, setLoading] = useState(!isCreateMode);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [profile, setProfile] = useState({ name: "" });
    const [discounts, setDiscounts] = useState([]);
    const [allDiscounts, setAllDiscounts] = useState([]);
    const [carrierFilter, setCarrierFilter] = useState("");

    useEffect(() => {
        const fetchDiscounts = async () => {
            try {
                const data = await ApiService.get("/api/discount/discounts/list");
                setAllDiscounts(data || []);
            } catch (err) {
                setError("Error fetching discounts.");
            }
        };

        const fetchProfile = async () => {
            if (!isCreateMode) {
                try {
                    const profileData = await ApiService.get(`/api/discount/${id}`);
                    setProfile(profileData.profile || {});
                    setDiscounts(
                        profileData.discounts.map((discount) => ({
                            ...discount,
                            activeField: discount.discount_value ? "discount_value" : "fixed_discount",
                        }))
                    );
                } catch (err) {
                    setError("Error fetching profile details.");
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchDiscounts();
        fetchProfile();
    }, [isCreateMode, id]);

    const handleProfileChange = (e) => {
        const { name, value } = e.target;
        setProfile((prev) => ({ ...prev, [name]: value }));
    };

    const handleDiscountChange = (discountId, e) => {
        const { name, value } = e.target;
        setDiscounts((prev) =>
            prev.map((discount) =>
                discount.discount_id === discountId
                    ? { ...discount, [name]: parseFloat(value) || "" }
                    : discount
            )
        );
    };

    const toggleDiscountField = (discountId, field) => {
        setDiscounts((prev) =>
            prev.map((discount) =>
                discount.discount_id === discountId
                    ? {
                          ...discount,
                          activeField: field,
                          ...(field === "discount_value" ? { fixed_discount: "" } : { discount_value: "" }),
                      }
                    : discount
            )
        );
    };

    const toggleDiscount = (discount) => {
        setDiscounts((prev) =>
            prev.some((d) => d.discount_id === discount.id)
                ? prev.filter((d) => d.discount_id !== discount.id)
                : [
                      ...prev,
                      {
                          discount_id: discount.id,
                          profile_id: profile.id,
                          discount_name: discount.name,
                          discount_value: "",
                          fixed_discount: "",
                          type: "percentage",
                          activeField: "discount_value",
                      },
                  ]
        );
    };

    const handleSave = async () => {
        setLoading(true);
        setError("");
        setSuccess("");

        try {
            const sanitizedProfile = { id: profile.id, name: profile.name };

            const sanitizedDiscounts = discounts.map((discount) => {
                const baseDiscount = {
                    discount_id: discount.discount_id,
                    discount_name: discount.discount_name,
                    type: discount.activeField === "discount_value" ? "percentage" : "fixed",
                    discount_value: discount.activeField === "discount_value" ? discount.discount_value : null,
                    fixed_discount: discount.activeField === "fixed_discount" ? discount.fixed_discount : null,
                };

                if (!isCreateMode) {
                    baseDiscount.id = discount.id || null;
                    baseDiscount.profile_id = profile.id || null;
                }

                return baseDiscount;
            });

            if (isCreateMode) {
                await ApiService.post("/api/discount", { profile: sanitizedProfile, discounts: sanitizedDiscounts });
                setSuccess("Profile created successfully.");
            } else {
                await ApiService.put(`/api/discount/${id}`, { profile: sanitizedProfile, discounts: sanitizedDiscounts });
                setSuccess("Profile updated successfully.");
            }
            navigate("/admin/discounts");
        } catch (err) {
            setError("Error saving profile.");
        } finally {
            setLoading(false);
        }
    };

    const filteredDiscounts = carrierFilter
        ? allDiscounts.filter((discount) => discount.carrier.toLowerCase().includes(carrierFilter.toLowerCase()))
        : allDiscounts;

    return (
        <Container className="small">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <Breadcrumb>
                    <Breadcrumb.Item onClick={() => navigate("/admin/discounts")}>Discount Profiles</Breadcrumb.Item>
                    <Breadcrumb.Item active>{isCreateMode ? "Create Profile" : "Edit Profile"}</Breadcrumb.Item>
                </Breadcrumb>
                <Button variant="primary" onClick={handleSave} size="sm">
                    Save
                </Button>
            </div>

            <Form>
                <Row className="mb-2">
                    <Col md={6}>
                        <Form.Group>
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                size="sm"
                                type="text"
                                name="name"
                                value={profile.name}
                                onChange={handleProfileChange}
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mb-2">
                    <Col md={6}>
                        <Form.Group>
                            <Form.Label>Filter by Carrier</Form.Label>
                            <Form.Control
                                size="sm"
                                type="text"
                                value={carrierFilter}
                                onChange={(e) => setCarrierFilter(e.target.value)}
                                placeholder="Enter carrier name (e.g., AMAZON)"
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>Surcharge Name</th>
                            <th>Carrier</th>
                            <th>Discount Value (%)</th>
                            <th>Fixed Discount ($)</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredDiscounts.map((discount) => {
                            const isAdded = discounts.some((d) => d.discount_id === discount.id);
                            const currentDiscount = discounts.find((d) => d.discount_id === discount.id) || {};

                            return (
                                <tr key={discount.id}>
                                    <td>{discount.name}</td>
                                    <td>{discount.carrier}</td>
                                    <td>
                                        <Form.Control
                                            size="sm"
                                            type="number"
                                            name="discount_value"
                                            value={currentDiscount.discount_value || ""}
                                            onChange={(e) => handleDiscountChange(discount.id, e)}
                                            onClick={() => toggleDiscountField(discount.id, "discount_value")}
                                            step="0.1"
                                            min="0"
                                            readOnly={currentDiscount.activeField !== "discount_value"}
                                        />
                                    </td>
                                    <td>
                                        <Form.Control
                                            size="sm"
                                            type="number"
                                            name="fixed_discount"
                                            value={currentDiscount.fixed_discount || ""}
                                            onChange={(e) => handleDiscountChange(discount.id, e)}
                                            onClick={() => toggleDiscountField(discount.id, "fixed_discount")}
                                            step="0.1"
                                            min="0"
                                            readOnly={currentDiscount.activeField !== "fixed_discount"}
                                        />
                                    </td>
                                    <td>
                                        <Button
                                            size="sm"
                                            variant={isAdded ? "danger" : "primary"}
                                            onClick={() => toggleDiscount(discount)}
                                        >
                                            {isAdded ? "Remove" : "Add"}
                                        </Button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </Form>
        </Container>
    );
};

export default DiscountProfileDetails;