import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ApiService from '../services/ApiService.js';
import { Button, Table, Spinner, Form, Pagination, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import IntegrationConfiguration from '../utils/IntegrationConfiguration.js';

const IntegrationManagement = () => {
    const [integrations, setIntegrations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [pageSize] = useState(10);
    const [sortField, setSortField] = useState('created_at');
    const [sortOrder, setSortOrder] = useState('desc');
    const [search, setSearch] = useState('');
    const navigate = useNavigate();

    const fetchIntegrations = async (page, size, sortField, sortOrder, search) => {
        setLoading(true);
        try {
            const params = { page, pageSize: size, sortField, sortOrder, search };
            const data = await ApiService.get('api/integration', params);
            setIntegrations(data.integrations || []);
            setTotalPages(data.totalPages || 1);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching integrations:', error);
            setIntegrations([]);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchIntegrations(currentPage, pageSize, sortField, sortOrder, search);
    }, [currentPage, sortField, sortOrder, search, pageSize]);

    const handleSort = (field) => {
        const newSortOrder = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortOrder(newSortOrder);
    };

    const handleSearch = (e) => {
        e.preventDefault();
        setCurrentPage(1);
        fetchIntegrations(1, pageSize, sortField, sortOrder, search);
    };

    const handleDelete = async (integrationId) => {
        if (window.confirm('Are you sure you want to delete this integration?')) {
            try {
                await ApiService.delete(`api/integration/${integrationId}`);
                setIntegrations(integrations.filter(i => i.integration_id !== integrationId));
            } catch (error) {
                console.error('Error deleting integration:', error);
            }
        }
    };

    return (
        <div className="container">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h5 className="mb-0" style={{ fontSize: '1rem' }}>Integration Management</h5>
                <Button variant="primary" size="sm" onClick={() => navigate('/admin/integrations/create')}>
                    Create Integration
                </Button>
            </div>

            <Form onSubmit={handleSearch} className="mb-2">
                <Row>
                    <Col xs={9}>
                        <Form.Control
                            type="text"
                            size="sm"
                            placeholder="Search by customer name or integration type..."
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </Col>
                    <Col xs={3}>
                        <Button type="submit" variant="secondary" size="sm" className="w-100">Search</Button>
                    </Col>
                </Row>
            </Form>

            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '150px' }}>
                    <Spinner animation="border" size="sm" />
                </div>
            ) : (
                <Table striped bordered hover size="sm" responsive>
                    <thead>
                        <tr style={{ fontSize: '0.85rem' }}>
                            <th onClick={() => handleSort('customer_name')}>Customer Name</th>
                            <th onClick={() => handleSort('api_user_name')}>API User</th>
                            <th onClick={() => handleSort('integration_type')}>Integration Type</th>
                            <th>Rate Card</th>
                            <th onClick={() => handleSort('created_at')}>Created At</th>
                            <th onClick={() => handleSort('updated_at')}>Updated At</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {integrations.length > 0 ? (
                            integrations.map((integration) => (
                                <tr key={integration.integration_id} style={{ fontSize: '0.85rem' }}>
                                    <td>{integration.customer_name}</td>
                                    <td>{integration.api_user_name}</td>
                                    <td>{IntegrationConfiguration.getIntegrationByKey(integration.integration_type)?.name || integration.integration_type}</td>
                                    <td>{integration.rate_card_name || 'N/A'}</td>
                                    <td>{new Date(integration.created_at).toLocaleString()}</td>
                                    <td>{new Date(integration.updated_at).toLocaleString()}</td>
                                    <td>
                                        <Button
                                            variant="outline-primary"
                                            className="me-1"
                                            size="sm"
                                            onClick={() => navigate(`/admin/integrations/${integration.integration_id}`)}
                                        >
                                            <FontAwesomeIcon icon={faEdit} />
                                        </Button>
                                        <Button
                                            variant="outline-danger"
                                            size="sm"
                                            onClick={() => handleDelete(integration.integration_id)}
                                        >
                                            <FontAwesomeIcon icon={faTrashAlt} />
                                        </Button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="6" className="text-center">No integrations found</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            )}

            <div className="d-flex justify-content-end mt-2">
                <Pagination size="sm">
                    <Pagination.Prev
                        onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                        disabled={currentPage === 1}
                    />
                    {[...Array(totalPages)].map((_, index) => (
                        <Pagination.Item
                            key={index + 1}
                            active={index + 1 === currentPage}
                            onClick={() => setCurrentPage(index + 1)}
                        >
                            {index + 1}
                        </Pagination.Item>
                    ))}
                    <Pagination.Next
                        onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                        disabled={currentPage === totalPages}
                    />
                </Pagination>
            </div>
        </div>
    );
};

export default IntegrationManagement;