import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useSearchParams, Link } from "react-router-dom";
import ApiService from "../services/ApiService.js";
import {
    Breadcrumb,
    Spinner,
    Container,
    Button,
    Form,
    Table,
    Modal,
    ToastContainer,
    Tabs,
    Tab,
    Card,
    InputGroup,
    Badge,
} from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomerDropdown from "../components/CustomerDropdown.js";
import IntegrationCreate from "./IntegrationCreate.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faSyncAlt, faEdit } from "@fortawesome/free-solid-svg-icons";

const ApiUserDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const defaultTab = searchParams.get("tab") || "general";

    const [apiUser, setApiUser] = useState({
        name: "",
        customerId: "",
        clientId: "",
        prodClientSecret: "",
        prodAuthorizationCode: "",
        devClientSecret: "",
        devAuthorizationCode: "",
        integrations: [],
    });
    const [customers, setCustomers] = useState([]);
    const [rateCards, setRateCards] = useState([]);
    const [markups, setMarkups] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState(defaultTab);

    const [showIntegrationModal, setShowIntegrationModal] = useState(false);
    const [editingIntegration, setEditingIntegration] = useState(null);
    const [mode, setMode] = useState("create");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const customersData = await ApiService.get("api/customer");
                const rateCardsData = await ApiService.get("/api/rate-card");
                const discountData = await ApiService.get("/api/discount");

                if (id !== "create") {
                    const apiUserData = await ApiService.get(`api/user/${id}`);
                    setApiUser({
                        name: apiUserData.apiUser.name,
                        customerId: apiUserData.apiUser.customer_id,
                        clientId: apiUserData.apiUser.client_id,
                        prodClientSecret: apiUserData.apiUser.prod_client_secret,
                        prodAuthorizationCode: apiUserData.apiUser.prod_authorization_code || "",
                        devClientSecret: apiUserData.apiUser.dev_client_secret,
                        devAuthorizationCode: apiUserData.apiUser.dev_authorization_code || "",
                        integrations: apiUserData.apiUser.integrations,
                    });
                    setMarkups(apiUserData.apiUser.markups || []);
                }
                setCustomers(customersData);
                setRateCards(rateCardsData);
                setMarkups(discountData);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.error(`Error: ${error.message}`);
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    useEffect(() => {
        setActiveTab(defaultTab);
    }, [defaultTab]);

    const handleTabChange = (key) => {
        setActiveTab(key);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setApiUser((prev) => ({ ...prev, [name]: value }));
    };

    const handleShowIntegrationModal = (mode, integration = null) => {
        setMode(mode);
        setEditingIntegration(integration);
        setShowIntegrationModal(true);
    };

    const handleCloseIntegrationModal = async () => {
        setShowIntegrationModal(false);
        setEditingIntegration(null);

        try {
            const updatedUser = await ApiService.get(`api/user/${id}`);
            setApiUser((prev) => ({
                ...prev,
                integrations: updatedUser.apiUser.integrations,
            }));
        } catch (error) {
            console.error("Error refreshing integrations:", error);
            toast.error("Failed to refresh integrations.");
        }
    };

    const handleIntegrationSubmit = async (e) => {
        e.preventDefault();
        try {
            const {
                integration_id,
                customer_name,
                rate_card_name,
                fuelIncludedLb,
                fuelIncludedOz,
                discount_profile_name,
                ...rest
            } = editingIntegration;

            const payload = {
                ...rest,
                customer_id: editingIntegration.customerId || apiUser.customerId,
                api_user_id: id,
            };

            if (mode === "edit") {
                await ApiService.put(
                    `/api/integration/${editingIntegration.integration_id}`,
                    payload
                );
                toast.success("Integration updated successfully!");
            } else {
                await ApiService.post(`/api/integration`, editingIntegration);
                toast.success("Integration created successfully!");
            }
            handleCloseIntegrationModal();
        } catch (error) {
            console.error("Error saving integration:", error);
            toast.error(`Failed to save integration: ${error.message}`);
        }
    };

    const handleRefresh = async (type, environment) => {
        try {
            const response = await ApiService.post(
                `api/user/${id}/refresh${environment}${type}`
            );
            setApiUser((prev) => ({
                ...prev,
                [`${environment.toLowerCase()}${type}`]: response[type.toLowerCase()],
            }));
            toast.success(`${environment} ${type} refreshed successfully!`);
        } catch (error) {
            console.error(`Error refreshing ${type}:`, error);
            toast.error(`Failed to refresh ${type}: ${error.message}`);
        }
    };

    const handleCopy = (value, message) => {
        navigator.clipboard.writeText(value).then(() => {
            toast.success(`${message} copied to clipboard!`);
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!apiUser.name || !apiUser.customerId) {
            toast.error("All fields are required.");
            return;
        }

        try {
            if (id !== "create") {
                await ApiService.put(`api/user/${id}`, apiUser);
                toast.success("API user updated successfully!");
            } else {
                await ApiService.post("api/user", apiUser);
                toast.success("API user created successfully!");
            }
            navigate("/admin/api_users");
        } catch (error) {
            console.error("Error saving API user:", error);
            toast.error(`Error: ${error.message}`);
        }
    };

    const formatFieldName = (name) =>
        name.replace(/([A-Z])/g, " $1").replace(/\b\w/g, (char) => char.toUpperCase());

    return (
        <Container>
            <ToastContainer />
            <Breadcrumb>
                <Breadcrumb.Item onClick={() => navigate("/admin/api_users")}>
                    API User Management
                </Breadcrumb.Item>
                <Breadcrumb.Item active className="fs-7">
                    {id ? "Edit API User" : "Create API User"}
                </Breadcrumb.Item>
            </Breadcrumb>
            <h5 className="fs-6">{id ? "Edit API User" : "Create API User"}</h5>

            {loading ? (
                <div
                    className="d-flex flex-column justify-content-center align-items-center"
                    style={{ height: "150px" }}
                >
                    <Spinner animation="border" />
                    <span className="mt-2 fs-7">Loading...</span>
                </div>
            ) : (
                <Tabs
                    id="api-user-tabs"
                    activeKey={activeTab}
                    onSelect={handleTabChange}
                    className="mb-3"
                    style={{ fontSize: "1rem" }}
                >
                    <Tab eventKey="general" title={<span className="fs-7">General</span>}>
                        <div className="d-flex">
                            <Button variant="primary ms-auto" style={{ fontSize: "0.85rem" }} type="submit">
                                {id !== "create" ? "Update API User" : "Create API User"}
                            </Button>
                        </div>
                        <Form style={{ fontSize: "0.85rem" }} onSubmit={handleSubmit}>
                            <Form.Group controlId="name">
                                <Form.Label className="fs-7">Name</Form.Label>
                                <Form.Control
                                    style={{ fontSize: "0.85rem" }}
                                    type="text"
                                    name="name"
                                    value={apiUser.name}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>

                            <Form.Group className="mt-3" controlId="customer">
                                <Form.Label className="fs-7">Customer</Form.Label>
                                <CustomerDropdown
                                    selectedCustomerId={apiUser.customerId}
                                    onChange={(customerId) =>
                                        setApiUser((prev) => ({ ...prev, customerId }))
                                    }
                                    customers={customers}
                                />
                            </Form.Group>
                        </Form>
                    </Tab>
                    <Tab eventKey="integrations" title={<span className="fs-7">Integrations</span>}>
                        <div className="d-flex">
                            <Button
                                variant="primary"
                                className="mb-3 ms-auto fs-7"
                                onClick={() => handleShowIntegrationModal("create")}
                                style={{ fontSize: "0.85rem" }}
                            >
                                Create Integration
                            </Button>
                        </div>
                        <Table striped bordered hover style={{ fontSize: "0.85rem" }}>
                            <thead>
                                <tr>
                                    <th>Integration</th>
                                    <th>Rate Card</th>
                                    <th>Discount Profile</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {apiUser.integrations.map((integration) => (
                                    <tr key={integration.integration_id}>
                                        <td>
                                            <Link
                                                to={`/admin/integrations/${integration.integration_id}`}
                                                className="text-decoration-none fs-7"
                                            >
                                                {formatFieldName(integration.integration_type)}
                                            </Link>
                                        </td>
                                        <td>
                                            <Link
                                                to={`/admin/rate_cards/preview/${integration.rate_card_id}`}
                                                className="text-decoration-none fs-7"
                                            >
                                                {integration.rate_card_name}
                                                <div>
                                                    {integration.fuelIncludedOz ? (
                                                        <Badge bg="info" text="dark" className="me-2 fs-7">
                                                            OZ - Fuel Included
                                                        </Badge>
                                                    ) : null}
                                                    {integration.fuelIncludedLb ? (
                                                        <Badge bg="info" text="dark fs-7">
                                                            LB - Fuel Included
                                                        </Badge>
                                                    ) : null}
                                                </div>
                                            </Link>
                                        </td>
                                        <td>
                                            <Link
                                                to={`/admin/discounts/${integration.markup_profile_id}`}
                                                className="text-decoration-none fs-7"
                                            >
                                                {integration.discount_profile_name}
                                            </Link>
                                        </td>
                                        <td>
                                            <Button
                                                variant="outline-primary"
                                                className="me-1 fs-7"
                                                size="sm"
                                                onClick={() =>
                                                    handleShowIntegrationModal("edit", integration)
                                                }
                                            >
                                                <FontAwesomeIcon icon={faEdit} />
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Tab>
                    <Tab eventKey="api-setup" title={<span className="fs-7">API Setup</span>}>
                        <Card className="mb-3" style={{ fontSize: "0.85rem" }}>
                            <Card.Header className="fs-7">Client ID</Card.Header>
                            <Card.Body>
                                <InputGroup>
                                    <Form.Control
                                        style={{ fontSize: "0.85rem" }}
                                        type="text"
                                        value={apiUser.clientId || ""}
                                        readOnly
                                    />
                                    <Button
                                        variant="outline-secondary"
                                        className="fs-7"
                                        onClick={() =>
                                            handleCopy(apiUser.clientId, "Client ID")
                                        }
                                    >
                                        <FontAwesomeIcon icon={faCopy} />
                                    </Button>
                                </InputGroup>
                            </Card.Body>
                        </Card>
                        <Card className="mb-3" style={{ fontSize: "0.85rem" }}>
                            <Card.Header className="fs-7">Production API Credentials</Card.Header>
                            <Card.Body>
                                <Form.Group>
                                    <Form.Label className="fs-7">Client Secret</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            style={{ fontSize: "0.85rem" }}
                                            type="text"
                                            value={apiUser.prodClientSecret || ""}
                                            readOnly
                                        />
                                        <Button
                                            variant="outline-secondary"
                                            style={{ fontSize: "0.85rem" }}
                                            onClick={() =>
                                                handleRefresh("ClientSecret", "Prod")
                                            }
                                        >
                                            <FontAwesomeIcon icon={faSyncAlt} />
                                        </Button>
                                        <Button
                                            variant="outline-secondary"
                                            style={{ fontSize: "0.85rem" }}
                                            onClick={() =>
                                                handleCopy(apiUser.prodClientSecret, "Production Client Secret")
                                            }
                                        >
                                            <FontAwesomeIcon icon={faCopy} />
                                        </Button>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label style={{ fontSize: "0.85rem" }}>Authorization Code</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            style={{ fontSize: "0.85rem" }}
                                            type="text"
                                            value={apiUser.prodAuthorizationCode || ""}
                                            readOnly
                                        />
                                        <Button
                                            variant="outline-secondary"
                                            style={{ fontSize: "0.85rem" }}
                                            onClick={() =>
                                                handleRefresh("AuthorizationCode", "Prod")
                                            }
                                        >
                                            <FontAwesomeIcon icon={faSyncAlt} />
                                        </Button>
                                        <Button
                                            variant="outline-secondary"
                                            style={{ fontSize: "0.85rem" }}
                                            onClick={() =>
                                                handleCopy(apiUser.prodAuthorizationCode, "Production Authorization Code")
                                            }
                                        >
                                            <FontAwesomeIcon icon={faCopy} />
                                        </Button>
                                    </InputGroup>
                                </Form.Group>
                            </Card.Body>
                        </Card>
                        <Card className="mb-3" style={{ fontSize: "0.85rem" }}>
                            <Card.Header style={{ fontSize: "0.85rem" }}>Development API Credentials</Card.Header>
                            <Card.Body>
                                <Form.Group>
                                    <Form.Label style={{ fontSize: "0.85rem" }}>Client Secret</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            style={{ fontSize: "0.85rem" }}
                                            type="text"
                                            value={apiUser.devClientSecret || ""}
                                            readOnly
                                        />
                                        <Button
                                            variant="outline-secondary"
                                            style={{ fontSize: "0.85rem" }}
                                            onClick={() =>
                                                handleRefresh("ClientSecret", "Dev")
                                            }
                                        >
                                            <FontAwesomeIcon icon={faSyncAlt} />
                                        </Button>
                                        <Button
                                            variant="outline-secondary"
                                            style={{ fontSize: "0.85rem" }}
                                            onClick={() =>
                                                handleCopy(apiUser.devClientSecret, "Development Client Secret")
                                            }
                                        >
                                            <FontAwesomeIcon icon={faCopy} />
                                        </Button>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label style={{ fontSize: "0.85rem" }}>Authorization Code</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            style={{ fontSize: "0.85rem" }}
                                            type="text"
                                            value={apiUser.devAuthorizationCode || ""}
                                            readOnly
                                        />
                                        <Button
                                            variant="outline-secondary"
                                            style={{ fontSize: "0.85rem" }}
                                            onClick={() =>
                                                handleRefresh("AuthorizationCode", "Dev")
                                            }
                                        >
                                            <FontAwesomeIcon icon={faSyncAlt} />
                                        </Button>
                                        <Button
                                            variant="outline-secondary"
                                            style={{ fontSize: "0.85rem" }}
                                            onClick={() =>
                                                handleCopy(apiUser.devAuthorizationCode, "Development Authorization Code")
                                            }
                                        >
                                            <FontAwesomeIcon icon={faCopy} />
                                        </Button>
                                    </InputGroup>
                                </Form.Group>
                            </Card.Body>
                        </Card>
                    </Tab>
                </Tabs>
            )}

            <Modal
                show={showIntegrationModal}
                onHide={handleCloseIntegrationModal}
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fs-7">
                        {mode === "edit" ? "Edit Integration" : "Create Integration"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <IntegrationCreate
                        integration={editingIntegration || {}}
                        onIntegrationChange={(updatedIntegration) =>
                            setEditingIntegration(updatedIntegration)
                        }
                        rateCards={rateCards}
                        markups={markups}
                        onSubmit={handleIntegrationSubmit}
                        mode={mode}
                    />
                </Modal.Body>
            </Modal>
        </Container>
    );
};

export default ApiUserDetails;