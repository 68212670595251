import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import ApiService from '../services/ApiService.js';
import { Table, Button, Spinner, Container, Pagination, Row, Col, Form } from 'react-bootstrap';
import { FaUpload, FaEye } from 'react-icons/fa';

const RateCardManagement = () => {
    const [rateCards, setRateCards] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [search, setSearch] = useState('');
    const pageSize = 10;
    const navigate = useNavigate();

    const fetchRateCards = useCallback(async () => {
        setLoading(true);
        try {
            const params = { page: currentPage, pageSize, search };
            const data = await ApiService.get('/api/rate-card', params);
            setRateCards(data.rateCards || []);
            setTotalPages(data.totalPages || 1);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching rate cards:', error);
            setLoading(false);
        }
    }, [currentPage, search]);

    useEffect(() => {
        fetchRateCards();
    }, [fetchRateCards]);

    return (
        <Container style={{ fontSize: '0.8rem' }}>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h5 style={{ fontSize: '1rem', margin: 0 }}>Rate Card Management</h5>
                <Button variant="primary" size="sm" onClick={() => navigate('/admin/rate_cards/upload')}>
                    <FaUpload /> Upload Rate Card
                </Button>
            </div>

            <Form className="mb-3" onSubmit={(e) => { e.preventDefault(); fetchRateCards(); }}>
                <Row>
                    <Col>
                        <Form.Control
                            type="text"
                            placeholder="Search by name..."
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            size="sm"
                        />
                    </Col>
                </Row>
            </Form>

            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '150px' }}>
                    <Spinner animation="border" size="sm" />
                </div>
            ) : (
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Filename</th>
                            <th>Uploaded At</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rateCards.map((card) => (
                            <tr key={card.id}>
                                <td>{card.name}</td>
                                <td>{card.filename}</td>
                                <td>{new Date(card.uploaded_at).toLocaleString()}</td>
                                <td>
                                    <Button
                                        variant="success"
                                        size="sm"
                                        onClick={() => navigate(`/admin/rate_cards/preview/${card.id}`)}
                                    >
                                        <FaEye />
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}

            <Pagination className="justify-content-end">
                <Pagination.Prev
                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                    disabled={currentPage === 1}
                />
                {[...Array(totalPages)].map((_, index) => (
                    <Pagination.Item
                        key={index}
                        active={index + 1 === currentPage}
                        onClick={() => setCurrentPage(index + 1)}
                    >
                        {index + 1}
                    </Pagination.Item>
                ))}
                <Pagination.Next
                    onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                    disabled={currentPage === totalPages}
                />
            </Pagination>
        </Container>
    );
};

export default RateCardManagement;