import React, { useState } from 'react';
import { Container, Form, Button, Card, Row, Col, Breadcrumb } from 'react-bootstrap';
import Papa from 'papaparse';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import ApiService from '../services/ApiService.js';

const RateCardUpload = () => {
    const [file, setFile] = useState(null);
    const [name, setName] = useState('');
    const [fuelIncludedOz, setFuelIncludedOz] = useState(false); // Checkbox for OZ - Fuel Included
    const [fuelIncludedLb, setFuelIncludedLb] = useState(false); // Checkbox for LB - Fuel Included
    const navigate = useNavigate();

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleUpload = async () => {
        if (!file || !name.trim()) {
            toast.error('Please provide a name and select a CSV file.');
            return;
        }

        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: async (results) => {
                const { data } = results;
                const filename = file.name;

                const payload = {
                    name,
                    filename,
                    rates: data,
                    fuelIncludedOz, // Send OZ checkbox value
                    fuelIncludedLb, // Send LB checkbox value
                };

                try {
                    const response = await ApiService.post('/api/rate-card/preview', payload);
                    toast.success('Rate card uploaded successfully!');
                    navigate(`/admin/rate_cards/preview`, {
                        state: { name, filename, rates: data, fuelIncludedOz, fuelIncludedLb },
                    });
                } catch (error) {
                    console.error('Error uploading rate card:', error);
                    toast.error('Failed to upload rate card.');
                }
            },
        });
    };

    return (
        <Container>
            <Breadcrumb className="mb-3">
                <Breadcrumb.Item onClick={() => navigate('/admin/rate_cards')}>Rate Cards</Breadcrumb.Item>
                <Breadcrumb.Item active>Rate Card Upload</Breadcrumb.Item>
            </Breadcrumb>
            <h5>Rate Card Upload</h5>
            <Card className="mt-4">
                <Card.Body>
                    <Form>
                        {/* Section: General Information */}
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="rateCardName">
                                    <Form.Label>Rate Card Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter rate card name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        {/* Section: Fuel Options */}
                        <hr className="my-4" />
                        <h6 className="mb-3">Fuel Options</h6>
                        <p className="text-muted mt-3">
                            <small>
                            Selecting "Fuel Included" indicates that the rates in the rate sheet already include fuel surcharges. 
                            If checked, this overrides the discount fuel surcharge calculations.
                            </small>
                        </p>
                        <Form.Group controlId="isOzFuelIncluded" className="mb-3">
                            <Form.Check
                                type="checkbox"
                                label="OZ - Fuel Included"
                                checked={fuelIncludedOz}
                                onChange={(e) => setFuelIncludedOz(e.target.checked)}
                            />
                            <Form.Text className="text-muted">
                                Fuel surcharges are included for packages weighing less than or up to 15 oz.
                            </Form.Text>
                        </Form.Group>

                        <Form.Group controlId="isLbFuelIncluded" className="mb-3">
                            <Form.Check
                                type="checkbox"
                                label="LB - Fuel Included"
                                checked={fuelIncludedLb}
                                onChange={(e) => setFuelIncludedLb(e.target.checked)}
                            />
                            <Form.Text className="text-muted">
                                Fuel surcharges are included for packages weighing greater than 15 oz.
                            </Form.Text>
                        </Form.Group>

                        {/* Section: Upload CSV File */}
                        <hr className="my-4" />
                        <Form.Group controlId="formFile">
                            <Form.Label>Upload CSV File</Form.Label>
                            <Form.Text className="text-muted d-block mb-3">
                                The file format must be a CSV with a header row. The first column should be <strong>Weight</strong>, 
                                followed by zone columns (e.g., <strong>1, 2, 3...</strong>). The weights are listed in the left-most column, 
                                and the corresponding rates for each zone should be in subsequent columns.
                            </Form.Text>
                            <Form.Control type="file" accept=".csv" onChange={handleFileChange} />
                        </Form.Group>

                        {/* Submit Button */}
                        <Button className="mt-3" variant="primary" onClick={handleUpload}>
                            Upload Rate Card
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default RateCardUpload;