import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import ApiService from '../services/ApiService.js';
import { Breadcrumb, Spinner, Container, Row, Col, Form, Button, Accordion, Modal } from 'react-bootstrap';
import CustomerDropdown from './CustomerDropdown.js';
import IntegrationConfiguration from '../utils/IntegrationConfiguration.js';
import CreateIntegration from './IntegrationCreate.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const IntegrationForm = ({ customerId, apiUserId, onSave }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [integration, setIntegration] = useState({
        customer_id: customerId || '',
        integration_type: '',
        integration_details: {},
        api_user_id: apiUserId || '',
        rate_card_id: '',
        markup_profile_id: '',
    });
    const [customIntegrationData, setCustomIntegrationData] = useState({});
    const [rateCards, setRateCards] = useState([]);
    const [markups, setMarkups] = useState([]);
    const [apiUsers, setApiUsers] = useState([]);
    const [filteredApiUsers, setFilteredApiUsers] = useState([]);
    const [loading, setLoading] = useState(id !== 'create' && !customerId);
    const [fieldErrors, setFieldErrors] = useState({});
    const integrationTypes = IntegrationConfiguration.getAllKeys();
    const [showCreateIntegration, setShowCreateIntegration] = useState(false);

    useEffect(() => {
        const fetchIntegration = async () => {
            try {
                if (id !== 'create' && !customerId) {
                    const data = await ApiService.get(`api/integration/${id}`);
                    setIntegration({
                        customer_id: data.customer_id || '',
                        integration_type: data.integration_type || '',
                        integration_details: data.integration_details || {},
                        api_user_id: data.api_user_id || '',
                        rate_card_id: data.rate_card_id || '',
                        markup_profile_id: data.markup_profile_id || '',
                    });
                    setCustomIntegrationData(data.integration_details || {});
                }
            } catch (error) {
                console.error('Error fetching integration details:', error);
                toast.error('Failed to fetch integration details');
            } finally {
                setLoading(false);
            }
        };

        const fetchApiUsers = async () => {
            try {
                const users = await ApiService.get('api/user');
                setApiUsers(users.apiUsers || []);
                filterApiUsersByCustomer(users.apiUsers || [], customerId || integration.customer_id);
            } catch (error) {
                console.error('Error fetching API users:', error);
                toast.error('Failed to fetch API users');
            }
        };

        const fetchRateCards = async () => {
            try {
                const response = await ApiService.get('/api/rate-card');
                setRateCards(response.rateCards || []);
            } catch (error) {
                console.error('Error fetching rate cards:', error);
                toast.error('Failed to fetch rate cards');
            }
        };

        const fetchMarkups = async () => {
            try {
                const response = await ApiService.get('/api/discount');
                setMarkups(response.profiles || []);
            } catch (error) {
                console.error('Error fetching markups:', error);
                toast.error('Failed to fetch markups');
            }
        };

        fetchIntegration();
        fetchApiUsers();
        fetchRateCards();
        fetchMarkups();
    }, [id, customerId, integration.customer_id]);

    const filterApiUsersByCustomer = (users, customerId) => {
        setFilteredApiUsers(users.filter((user) => user.customer_id === Number(customerId)));
    };

    const handleCustomerChange = (customerId) => {
        setIntegration((prev) => ({
            ...prev,
            customer_id: customerId,
            api_user_id: '',
        }));
        filterApiUsersByCustomer(apiUsers, customerId);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setIntegration((prev) => ({
            ...prev,
            [name]: value || '',
        }));
    };

    const handleCustomFieldChange = (fieldData) => {
        setCustomIntegrationData(fieldData);
    };

    const formatFieldName = (name) =>
        name.replace(/([A-Z])/g, ' $1').replace(/\b\w/g, (char) => char.toUpperCase());

    const renderField = (key, value = '', section = null) => {
        const errorKey = section ? `${section}.${key}` : key;
        const isError = !!fieldErrors[errorKey];
        const fieldValue = typeof value === 'string' ? value : '';

        return (
            <Col md={6} key={key}>
                <Form.Group className="mb-2">
                    <Form.Label>
                        {formatFieldName(key)}
                    </Form.Label>
                    <Form.Control
                        size="sm"
                        type="text"
                        name={key}
                        value={fieldValue}
                        onChange={(e) =>
                            section
                                ? handleCustomFieldChange({
                                      ...customIntegrationData,
                                      [section]: { ...customIntegrationData[section], [key]: e.target.value },
                                  })
                                : handleCustomFieldChange({ ...customIntegrationData, [key]: e.target.value })
                        }
                        isInvalid={isError}
                    />
                    {isError && <Form.Control.Feedback type="invalid">{fieldErrors[errorKey]}</Form.Control.Feedback>}
                </Form.Group>
            </Col>
        );
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            const payload = {
                ...integration,
                integration_details: customIntegrationData,
            };

            if (id !== 'create' && !customerId) {
                await ApiService.put(`api/integration/${id}`, payload);
                toast.success('Integration updated successfully');
            } else {
                await ApiService.post('api/integration', payload);
                toast.success('Integration created successfully');
            }

            if (!customerId) {
                navigate('/admin/integrations');
            } else {
                onSave();
            }
        } catch (error) {
            console.error('Error saving integration:', error);
            toast.error(error.response.data?.message);
        }
    };

    return (
        <Container>
            <ToastContainer />
            <Breadcrumb className="mb-3">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/admin/integrations' }}>
                    Integration Management
                </Breadcrumb.Item>
                <Breadcrumb.Item active>{id === 'create' ? 'Create Integration' : 'Edit Integration'}</Breadcrumb.Item>
            </Breadcrumb>

            <h5 className="mb-3">{id === 'create' || customerId ? 'Create Integration' : 'Edit Integration'}</h5>
            {loading ? (
                <Spinner animation="border" />
            ) : (
                <Form onSubmit={handleFormSubmit}>
                    <Row>
                        <Col>
                            <Form.Group className="mb-2">
                                <CustomerDropdown
                                    onChange={handleCustomerChange}
                                    selectedCustomerId={integration.customer_id}
                                />
                            </Form.Group>

                            <Form.Group className="mb-2">
                                <Form.Label>API User</Form.Label>
                                <Form.Select
                                    name="api_user_id"
                                    value={integration.api_user_id || ''}
                                    onChange={handleInputChange}
                                    size="sm"
                                >
                                    <option value="" disabled>
                                        Select API User
                                    </option>
                                    {filteredApiUsers.map((user) => (
                                        <option key={user.api_user_id} value={user.api_user_id}>
                                            {user.name}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-2">
                                <Form.Label>Integration Type</Form.Label>
                                <Form.Select
                                    name="integration_type"
                                    value={integration.integration_type || ''}
                                    onChange={handleInputChange}
                                    size="sm"
                                >
                                    <option value="" disabled>
                                        Select Integration Type
                                    </option>
                                    {integrationTypes.map((type) => (
                                        <option key={type} value={type}>
                                            {IntegrationConfiguration.getIntegrationByKey(type)?.name || type}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>

                            <Modal show={showCreateIntegration} onHide={() => setShowCreateIntegration(false)} size="lg" backdrop="static" centered>
                                <Modal.Header closeButton>
                                    <Modal.Title>Create Integration</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <CreateIntegration
                                        integration={integration}
                                        customIntegrationData={customIntegrationData}
                                        onCustomFieldChange={handleCustomFieldChange}
                                        rateCards={rateCards}
                                        markups={markups}
                                    />
                                </Modal.Body>
                            </Modal>

                            <Accordion>
                                {Object.entries(
                                    IntegrationConfiguration.getIntegrationByKey(integration.integration_type)
                                        ?.setupFields || {}
                                ).map(([section, fields], idx) => (
                                    <Accordion.Item eventKey={idx.toString()} key={section}>
                                        <Accordion.Header>{formatFieldName(section)}</Accordion.Header>
                                        <Accordion.Body>
                                            <Row>
                                                {typeof fields === 'object'
                                                    ? Object.entries(fields).map(([key, value]) =>
                                                          renderField(key, value, section)
                                                      )
                                                    : renderField(section, fields)}
                                            </Row>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))}
                            </Accordion>

                            <div className="d-flex justify-content-end mt-2">
                                <Button variant="primary" type="submit" size="sm" onClick={() => setShowCreateIntegration(true)}>
                                    {id && !customerId ? 'Update Integration' : 'Create Integration'}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            )}
        </Container>
    );
};

export default IntegrationForm;