import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Table, Button, Row, Col, Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';
import ApiService from '../services/ApiService.js';

const RateCardPreview = () => {
    const { state } = useLocation(); // Original rows are passed as state
    const navigate = useNavigate();

    const handleSave = async () => {
        try {
            await ApiService.post('/api/rate-card/save', state);
            toast.success('Rate card saved successfully!');
            navigate('/admin/rate_cards'); // Redirect to management screen
        } catch (error) {
            console.error('Error saving rate card:', error);
            toast.error('Failed to save rate card.');
        }
    };

    // Extract column names, ensuring "Weight" is always first
    const columns = ['Weight', ...Object.keys(state.rates[0]).filter((key) => key !== 'Weight')];

    return (
        <Container>
            <Row className="mb-3">
                <Col>
                    <h5>Rate Card Preview</h5>
                    <p><strong>Name:</strong> {state.name}</p>

                    {/* Badges for fuelIncluded */}
                    <div className="mb-3">
                        {state.fuelIncludedOz && (
                            <span className="badge bg-info text-dark me-2">OZ - Fuel Included</span>
                        )}
                        {state.fuelIncludedLb && (
                            <span className="badge bg-info text-dark">LB - Fuel Included</span>
                        )}
                    </div>
                </Col>
                <Col className="text-end">
                    <Button variant="primary" onClick={handleSave} className="me-2">
                        Save Rate Card
                    </Button>
                    <Button variant="secondary" onClick={() => navigate('/admin/rate_cards')}>
                        Cancel
                    </Button>
                </Col>
            </Row>

            <div style={{ overflowX: 'auto', maxHeight: '80vh', position: 'relative' }}>
                <Table striped bordered hover size="sm" style={{ tableLayout: 'fixed' }}>
                    <thead style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                        <tr>
                            {columns.map((key, index) => (
                                <th key={index}>{key}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {state.rates.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {columns.map((col, colIndex) => (
                                    <td key={colIndex}>{row[col]}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </Container>
    );
};

export default RateCardPreview;