import React, { useEffect, useState, useCallback } from 'react';
import { Table, Button, Spinner, Container, Pagination, Row, Col, Form, Modal } from 'react-bootstrap';
import { FaUpload, FaDownload } from 'react-icons/fa';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import ApiService from '../services/ApiService.js';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AmazonZoneManagement = () => {
    const [zones, setZones] = useState([]);
    const [loading, setLoading] = useState(true);
    const [uploading, setUploading] = useState(false); // Added uploading state
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [csvFile, setCsvFile] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [search, setSearch] = useState('');
    const pageSize = 10;

    const fetchZones = useCallback(async () => {
        setLoading(true);
        try {
            const params = { page: currentPage, pageSize, search };
            const data = await ApiService.get('/api/amazon-zone/distinct', params);
            setZones(data.zones || []);
            setTotalPages(data.totalPages || 1);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching zones:', error);
            toast.error('Failed to fetch zones.');
            setLoading(false);
        }
    }, [currentPage, search]);

    useEffect(() => {
        fetchZones();
    }, [fetchZones]);

    const handleUpload = () => {
        if (!csvFile) {
            toast.error('Please select a CSV file.');
            return;
        }

        setUploading(true); // Show spinner on upload start
        Papa.parse(csvFile, {
            header: true,
            complete: async (results) => {
                try {
                    await ApiService.post('/api/amazon-zone/upload', { rows: results.data });
                    toast.success('Zones uploaded successfully!');
                    setShowUploadModal(false);
                    fetchZones();
                } catch (error) {
                    console.error('Error uploading CSV:', error);
                    toast.error('Failed to upload zones.');
                } finally {
                    setUploading(false); // Hide spinner after upload completion or error
                }
            },
        });
    };

    const handleExport = async (fromZipCode) => {
        try {
            const response = await ApiService.get(`/api/amazon-zone/export?from_zip_code=${fromZipCode}`);
            const csvContent = Papa.unparse(response);
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            saveAs(blob, `${fromZipCode}_zones.csv`);
            toast.success(`Exported zones for ${fromZipCode}!`);
        } catch (error) {
            console.error('Error exporting zones:', error);
            toast.error(`Failed to export zones for ${fromZipCode}.`);
        }
    };

    return (
        <Container style={{ fontSize: '0.8rem' }}>
            <ToastContainer />

            <div className="d-flex justify-content-between align-items-center mb-3">
                <h5 style={{ fontSize: '1rem', margin: 0 }}>Amazon Zone Management</h5>
                <Button variant="primary" size="sm" onClick={() => setShowUploadModal(true)}>
                    <FaUpload /> Upload Zones
                </Button>
            </div>

            <Form className="mb-3" onSubmit={(e) => { e.preventDefault(); fetchZones(); }}>
                <Row>
                    <Col xs={9}>
                        <Form.Control
                            type="text"
                            placeholder="Search by From Zip Code..."
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            size="sm"
                            style={{ fontSize: '0.8rem' }}
                        />
                    </Col>
                    <Col xs={3}>
                        <Button type="submit" variant="secondary" size="sm" className="w-100" style={{ fontSize: '0.8rem' }}>
                            Search
                        </Button>
                    </Col>
                </Row>
            </Form>

            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '150px' }}>
                    <Spinner animation="border" size="sm" />
                </div>
            ) : (
                <>
                    <Table striped bordered hover size="sm" responsive style={{ fontSize: '0.8rem' }}>
                        <thead>
                            <tr>
                                <th>From Zip Code</th>
                                <th>Zone Count</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {zones.length > 0 ? (
                                zones.map((zone) => (
                                    <tr key={zone.from_zip_code}>
                                        <td>{zone.from_zip_code}</td>
                                        <td>{zone.count}</td>
                                        <td>
                                            <Button
                                                variant="success"
                                                size="sm"
                                                style={{ fontSize: '0.8rem' }}
                                                onClick={() => handleExport(zone.from_zip_code)}
                                            >
                                                <FaDownload />
                                            </Button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="3" className="text-center" style={{ fontSize: '0.8rem' }}>
                                        No zones found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>

                    <div className="d-flex justify-content-end">
                        <Pagination size="sm">
                            <Pagination.Prev
                                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                                disabled={currentPage === 1}
                            />
                            {[...Array(totalPages)].map((_, index) => (
                                <Pagination.Item
                                    key={index + 1}
                                    active={index + 1 === currentPage}
                                    onClick={() => setCurrentPage(index + 1)}
                                    style={{ fontSize: '0.8rem' }}
                                >
                                    {index + 1}
                                </Pagination.Item>
                            ))}
                            <Pagination.Next
                                onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                                disabled={currentPage === totalPages}
                            />
                        </Pagination>
                    </div>
                </>
            )}

            {/* Upload Modal */}
            <Modal show={showUploadModal} onHide={() => setShowUploadModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Upload Zones</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Select CSV File</Form.Label>
                            <Form.Control
                                type="file"
                                accept=".csv"
                                onChange={(e) => setCsvFile(e.target.files[0])}
                            />
                        </Form.Group>
                        {uploading && (
                            <div className="text-center mt-3">
                                <Spinner animation="border" size="sm" />
                                <span className="ml-2"> Uploading...</span>
                            </div>
                        )}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size="sm" onClick={() => setShowUploadModal(false)} disabled={uploading}>
                        Cancel
                    </Button>
                    <Button variant="primary" size="sm" onClick={handleUpload} disabled={uploading}>
                        Upload
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default AmazonZoneManagement;