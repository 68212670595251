import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import ApiService from '../services/ApiService.js';
import { Table, Button, Spinner, Container, Pagination, Row, Col, Form } from 'react-bootstrap';
import { FaPlus, FaEdit } from 'react-icons/fa';

const DiscountManagement = () => {
    const [profiles, setProfiles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [search, setSearch] = useState('');
    const pageSize = 10;
    const navigate = useNavigate();

    const fetchProfiles = useCallback(async () => {
        setLoading(true);
        try {
            const params = { page: currentPage, pageSize, search };
            const data = await ApiService.get('/api/discount', { params }); // Updated endpoint with params
            setProfiles(data.profiles || []);
            setTotalPages(data.totalPages || 1);
        } catch (error) {
            console.error('Error fetching profiles:', error);
        } finally {
            setLoading(false);
        }
    }, [currentPage, search]);

    useEffect(() => {
        fetchProfiles();
    }, [fetchProfiles]);

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    return (
        <Container style={{ fontSize: '0.8rem' }}>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h5 style={{ fontSize: '1rem', margin: 0 }}>Surcharge Profiles</h5>
                <Button variant="primary" size="sm" onClick={() => navigate('/admin/discounts/create')}>
                    <FaPlus /> Add Profile
                </Button>
            </div>

            <Form className="mb-3" onSubmit={(e) => { e.preventDefault(); fetchProfiles(); }}>
                <Row>
                    <Col>
                        <Form.Control
                            type="text"
                            placeholder="Search by name..."
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            size="sm"
                        />
                    </Col>
                </Row>
            </Form>

            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '150px' }}>
                    <Spinner animation="border" size="sm" />
                </div>
            ) : (
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Created At</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {profiles.map((profile) => (
                            <tr key={profile.id}>
                                <td>{profile.name}</td>
                                <td>{new Date(profile.created_at).toLocaleString()}</td>
                                <td>
                                    <Button
                                        variant="success"
                                        size="sm"
                                        onClick={() => navigate(`/admin/discounts/${profile.id}`)}
                                    >
                                        <FaEdit />
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}

            <Pagination className="justify-content-end">
                <Pagination.Prev
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                />
                {[...Array(totalPages)].map((_, index) => (
                    <Pagination.Item
                        key={index}
                        active={index + 1 === currentPage}
                        onClick={() => handlePageChange(index + 1)}
                    >
                        {index + 1}
                    </Pagination.Item>
                ))}
                <Pagination.Next
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                />
            </Pagination>
        </Container>
    );
};

export default DiscountManagement;